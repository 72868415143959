<template>
	<div>
		<h2>{{ $t('mouvement.lieux_stationnement') }}</h2>

        <CustomTable
			ref="table"
			id_table="select_tiers"
			:busy.sync="table_busy"
			primaryKey="tiers_id"
			:hrefsRoutes="config_table_hrefs"
			:hide_if_empty="true"
			:base-filters="filters"
			:rawColumns="rawColumnNames"
			:transformer="['TierTransformer', 'WithPhone']"
		/>
	</div>
</template>

<script type="text/javascript">
    import Navigation from '@/mixins/Navigation.js'
    import Shutter from '@/mixins/Shutter.js'

	export default {
		name: 'EditLieuStationnement',
		mixins: [Navigation, Shutter],
		data () {
			return {
				table_busy: true,
				rawColumnNames: ['phones_number'],
				config_table_hrefs: {
					'tiers_rs': {
						routeUniqueName: 'tiersTableauBord',
						routeName: 'tiersTableauBord',
						params: {
							tiers_id: 'tiers_id'
						}
					}
				},
                events_tab: {
                    'TableAction::selectLieuStationnement': this.chooseLieu,
				},
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {

			},

			chooseLieu(tiers) {
				this.ok(tiers)
				this.shutterPanel().close('mvt-lieu-stationnement')
			}
		},

		computed: {
			filters() {
				let filters = {
					tiers: {
						column: 'tiers_archive',
						operator: 'isEqualTo',
						value: 0
					}
				}

				return filters
			},
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}

</script>